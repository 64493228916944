import { isRecord } from "./primitives";

export function isSuccessHandlerResponse<T>(
  res: unknown
): res is T & { data: unknown } {
  return (
    isRecord(res) &&
    "status" in res &&
    "message" in res &&
    "data" in res &&
    Object.keys(res).length === 3
  );
}

export function hasLogId(data: unknown): data is { logId: string } {
  return isRecord(data) && "logId" in data;
}
