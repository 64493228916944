import { memo } from "react";
import { getErrorLogIds } from "@timeedit/registration-shared";
import { Text } from "../Typography/Text";

type ErrorLogIdProps = {
  error: unknown;
};

export const ErrorLogId = memo(function ErrorLogId({ error }: ErrorLogIdProps) {
  const logIds = getErrorLogIds(error);
  if (logIds.length === 0) return null;
  if (!Array.isArray(logIds) || logIds.length === 1) {
    return (
      <Text className="error-log-id__text-container">Log Id: {logIds}</Text>
    );
  }

  return (
    <div className="error-log-id__text-container">
      <Text>Log ids: </Text>
      <br />
      {logIds.map((logId) => (
        <div key={logId}>
          <Text>{logId}</Text>
          <br />
        </div>
      ))}
    </div>
  );
});
