import { RegistrationError, translations } from "@timeedit/registration-shared";
import { useMemo } from "react";
import {
  RegistrationCalendar,
  Spin,
  Title,
  ScheduleListView,
  CalendarEvent,
  createScheduleModalContent,
} from "@timeedit/registration-components";
import "./schedule.scss";
import { Layout } from "antd";
import { ViewsProps } from "react-big-calendar";
import { useFetchRegistrationQuery } from "../../redux/api/registration";
import { useAppSelector, useMapping } from "../../redux/hooks";

const { Header, Content } = Layout;

export function Schedule() {
  const views = createViews();
  const { allowedDateInterval, dateInterval } = useAppSelector(
    (state) => state.schedule
  );
  const mapping = useMapping();
  const registration = useFetchRegistrationQuery();

  if (registration.isError) {
    throw new RegistrationError({
      errors: [registration.error],
    });
  }

  if (registration.isLoading || !registration.data) {
    return <Spin contained />;
  }

  const title = (
    <Title level={3} className={"schedule-page__header-title"}>
      {translations.schedule}
    </Title>
  );

  return (
    <Layout>
      <Header className={"schedule-page__header"}>{title}</Header>
      <Content className={"schedule-page__content"}>
        <RegistrationCalendar
          allowedDateInterval={allowedDateInterval}
          dateInterval={dateInterval}
          mapping={mapping}
          registration={registration.data}
          translations={translations}
          views={views}
          title={title}
          eventModalContent={createScheduleModalContent({
            registration: registration.data,
            mapping,
            translations,
          })}
        />
      </Content>
    </Layout>
  );

  function createViews(): ViewsProps<CalendarEvent> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(
      () => ({
        work_week: true,
        agenda: ScheduleListView.default,
      }),
      []
    );
  }
}
