import { Modal, ModalProps } from "antd";
import "./statusModal.scss";
import { SeverityIcon, Severity } from "@timeedit/ui-components";
import { JSX } from "react";

type StatusModalTitleProps = {
  title: string;
  severity: Severity;
  iconAlt: string;
};
function StatusModalTitle({ iconAlt, severity, title }: StatusModalTitleProps) {
  return (
    <div className="status-modal-title__container">
      <SeverityIcon alt={iconAlt} severity={severity} size={36} />
      {title}
    </div>
  );
}

type StatusModalProps = {
  title: string;
  text: JSX.Element;
  severity: Severity;
  iconAlt: string;
} & ModalProps;
export function StatusModal(props: StatusModalProps) {
  return (
    <Modal
      {...props}
      title={
        <StatusModalTitle
          iconAlt={props.iconAlt}
          severity={props.severity}
          title={props.title}
        />
      }
    >
      {props.text}
    </Modal>
  );
}
