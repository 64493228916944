import {
  RegistrationMain,
  Spin,
  OnAllocate,
  OnDeallocate,
  StatusModal,
  ErrorLogId,
  usePrevious,
} from "@timeedit/registration-components";
import { useAppSelector, useMapping } from "../../redux/hooks";
import {
  useSwapMutation,
  useDeallocateMutation,
  useFetchRegistrationQuery,
} from "../../redux/api/registration";
import { useMutationSubscriptionRef } from "../../utils/hooks";
import {
  RegistrationError,
  getErrorMessage,
  getStatusCode,
  isDefined,
  isArray,
  getErrorLogIds,
  SaveStudentsResult,
} from "@timeedit/registration-shared";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorTexts, getKnownErrorTexts } from "../../utils/errors";
import { useNavigateWithSignature } from "../useNavigateWithSignature";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";

export function RegistrationPage() {
  const mapping = useMapping();
  const { allowedDateInterval, dateInterval } = useAppSelector(
    (state) => state.schedule
  );
  const signal = useAppSelector((state) => state.computed.signal);
  const navigateWithSignature = useNavigateWithSignature();
  const params = useParams();

  const allocateRef = useMutationSubscriptionRef();
  const [swap, swapResult] = useSwapMutation();

  const deallocateRef = useMutationSubscriptionRef();
  const [deallocate, deallocateResult] = useDeallocateMutation();

  const registration = useFetchRegistrationQuery();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalTexts, setErrorModalTexts] = useState<ErrorTexts>({
    text: "",
    title: "",
    knownError: false,
    logId: "",
  });

  useHandleAllocationResult({
    setErrorModalTexts,
    setOpenErrorModal,
    deallocateIsError: deallocateResult.isError,
    deallocateError: deallocateResult.error,
    swapIsError: swapResult.isError,
    swapError: swapResult.error,
    swapSuccess: swapResult.isSuccess,
    swapData: swapResult.data,
  });

  const [currentLoadingTrack, setCurrentLoadingTrack] = useState(0);

  const isLoading =
    registration.isLoading ||
    registration.isFetching ||
    swapResult.isLoading ||
    deallocateResult.isLoading;

  const prevIsLoading = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && prevIsLoading) {
      setCurrentLoadingTrack(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (registration.isError) {
    throw new RegistrationError({
      errors: [registration.error],
    });
  }

  if (registration.isLoading || !registration.data) {
    return <Spin contained />;
  }

  return (
    <>
      <RegistrationMain
        params={params}
        signal={signal}
        allocationControlActions={{
          onAllocate: handleAllocate,
          onDeallocate: handleDeallocate,
          currentLoadingTrack,
        }}
        navigate={navigateWithSignature}
        mappingData={mapping.store.mappingData}
        registration={registration.data}
        allowedDateInterval={allowedDateInterval}
        dateInterval={dateInterval}
      />

      <StatusModal
        open={openErrorModal}
        title={errorModalTexts.title}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
        onOk={() => {
          setOpenErrorModal(false);
        }}
        text={
          <StatusModalText
            text={errorModalTexts.text}
            logId={errorModalTexts.logId}
          />
        }
        severity={errorModalTexts.knownError ? "information" : "error"}
        iconAlt={errorModalTexts.knownError ? "Information" : "Error"}
      />
    </>
  );

  function handleAllocate({ trackId, newObjectId, prevObjectIds }: OnAllocate) {
    if (!isDefined(registration.data)) return;
    setCurrentLoadingTrack(trackId);
    allocateRef.current = swap({
      studentId: registration.data.studentId,
      newObjectId,
      prevObjectIds,
      conflictControl: "none",
    });
  }

  function handleDeallocate({ trackId, objectIds }: OnDeallocate) {
    if (!isDefined(registration.data)) return;
    setCurrentLoadingTrack(trackId);
    const studentInfo = { [registration.data.studentId]: objectIds };
    deallocateRef.current = deallocate({ studentInfo });
  }
}

export type UseHandleAllocationResultProps = {
  swapSuccess: boolean;
  swapError: FetchBaseQueryError | SerializedError | undefined;
  swapData: SaveStudentsResult | undefined;
  swapIsError: boolean;
  deallocateError: FetchBaseQueryError | SerializedError | undefined;
  deallocateIsError: boolean;
  setOpenErrorModal: Dispatch<SetStateAction<boolean>>;
  setErrorModalTexts: Dispatch<SetStateAction<ErrorTexts>>;
};
export function useHandleAllocationResult({
  setErrorModalTexts,
  setOpenErrorModal,
  deallocateIsError,
  swapData,
  deallocateError,
  swapError,
  swapIsError,
  swapSuccess,
}: UseHandleAllocationResultProps) {
  useEffect(() => {
    if (swapSuccess && swapData?.failedGroups) {
      const hasErrors = Object.values(swapData.failedGroups).some(
        (group) =>
          group?.some(
            (error) =>
              isDefined(error.reasons?.failsWithMessage) ||
              isDefined(error.reasons?.failsWithTracks)
          )
      );

      if (hasErrors) {
        setErrorModalTexts(
          getKnownErrorTexts({
            kind: "failedGroups",
            failedGroups: swapData.failedGroups,
            logId: swapData.logId ?? "",
          })
        );

        setOpenErrorModal(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapData?.failedGroups, swapData?.logId, swapSuccess]);

  useHandleAllocateError({
    error: swapError,
    isError: swapIsError,
    setErrorModalTexts,
    setOpenErrorModal,
  });
  useHandleAllocateError({
    error: deallocateError,
    isError: deallocateIsError,
    setErrorModalTexts,
    setOpenErrorModal,
  });
}

type HandleAllocateErrorProps = {
  error: FetchBaseQueryError | SerializedError | undefined;
  isError: boolean;
} & Pick<
  UseHandleAllocationResultProps,
  "setOpenErrorModal" | "setErrorModalTexts"
>;
function useHandleAllocateError({
  isError,
  setOpenErrorModal,
  setErrorModalTexts,
  error,
}: HandleAllocateErrorProps) {
  useEffect(() => {
    if (isError && isDefined(error)) {
      const errorMessage = getErrorMessage(error);
      const errorCode = getStatusCode(error);

      const logId = getErrorLogIds(error);

      setErrorModalTexts(
        getKnownErrorTexts({
          kind: "message",
          errorMessage,
          errorCode,
          logId: isArray(logId) ? logId[0] : logId,
        })
      );

      setOpenErrorModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);
}

type StatusModalTextProps = {
  text: string;
  logId: string | string[] | undefined;
};
function StatusModalText({ text, logId }: StatusModalTextProps) {
  return (
    <>
      <p>{text}</p>
      <br />
      <ErrorLogId error={{ logId }} />
    </>
  );
}
