import { translations } from "@timeedit/registration-shared";
import { useRouteError } from "react-router-dom";
import {
  ErrorInfo,
  useRecoverFunction,
} from "@timeedit/registration-components";
import { useAppSelector } from "../redux/hooks";
import { urls } from "./RootPage/AuthRoot";

export function ErrorPage() {
  const error = useRouteError();

  const { loginData } = useAppSelector((state) => state.login);

  const logoutRedirect = `${urls.AUTH_URL}/${loginData?.customerSignature}`;
  const recover = useRecoverFunction(error, logoutRedirect);

  return (
    <ErrorInfo
      error={error}
      recover={recover}
      translations={{
        retry: translations.retry,
        title: translations.errorInRegistration,
      }}
    />
  );
}
