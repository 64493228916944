import { Spin } from "../../Spin";
import cn from "classnames";
import "./registrationLoadingSpinner.scss";

const defaultTranslations = {
  registrationLoadingLine1: "Loading your modules...",
  registrationLoadingLine2:
    "This might take some time, please do not reload the page.",
} as const;

interface Props {
  className?: string;
  translations?: Record<keyof typeof defaultTranslations, string> &
    Record<string, string>;
}
export function RegistrationLoadingSpinner({
  className = "",
  translations = defaultTranslations,
}: Props) {
  return (
    <div className={cn("registration__loading-spinner", className)}>
      <span className="registration__loading-spinner__container">
        <Spin contained />
        <span className="registration__loading-spinner__text">
          {translations.registrationLoadingLine1}
          <br />
          {translations.registrationLoadingLine2}
        </span>
      </span>
    </div>
  );
}
