import {
  getErrorMessage,
  isDefined,
  RegistrationError,
} from "@timeedit/registration-shared";
import { AuthContext } from "@timeedit/ui-components";
import { useCallback, useContext, useMemo } from "react";

export function useRecoverFunction(error: unknown, logoutRedirect: string) {
  const logout = useContext(AuthContext)?.logout;
  const message = getErrorMessage(error);
  const unAuthorized = message.includes("Unauthorized access");

  const unAuthorizedRetry = useCallback(() => {
    if (isDefined(logout)) {
      logout();
    }
    window.location.replace(logoutRedirect);
  }, [logout, logoutRedirect]);

  return useMemo(() => {
    if (unAuthorized) {
      return unAuthorizedRetry;
    }

    // Default recover is to refresh the page
    return error instanceof RegistrationError
      ? () => error.recover()
      : () => location.reload();
  }, [error, unAuthorized, unAuthorizedRetry]);
}
