import { ITEToken, translations } from "@timeedit/registration-shared";
import { ITETokenInfo } from "@timeedit/types/lib/types";
import { AuthWrapper } from "@timeedit/ui-components";
import { useAppDispatch, useLazyUserData } from "../../redux/hooks";
import { useLazyFetchTeOrganizationQuery } from "../../redux/api/te";
import { setLoginData } from "../../redux/slices/user";
import { useLazyFetchRegistrationQuery } from "../../redux/api/registration";
import { EAppId } from "@timeedit/types/lib/enums";
import { RouterWrapper } from "../RouterWrapper";
import { ErrorInfo } from "@timeedit/registration-components";

export const urls: ITETokenInfo = {
  BASE_URL: import.meta.env.SSO_BASE_URL,
  AUTH_URL: import.meta.env.SSO_AUTH_DOMAIN,
};

export function AuthRoot() {
  const dispatch = useAppDispatch();

  const [triggerFetchOrganization, org] = useLazyFetchTeOrganizationQuery();

  const [triggerFetchUserData, user] = useLazyUserData();

  const [triggerFetchRegistration] = useLazyFetchRegistrationQuery();

  if (org.isError || user.isError) {
    return (
      <ErrorInfo
        error={org.isError ? org.error : user.error}
        recover={onLogout}
        translations={{
          retry: translations.retry,
          title: translations.errorInRegistration,
        }}
      />
    );
  }

  return (
    <AuthWrapper
      appId={EAppId.TE_REGISTRATION}
      authUrl={urls.AUTH_URL}
      renderChildrenWhileLoading={false}
      onLoginSuccess={onLoginSuccess}
      onLogout={onLogout}
      customerSignature={org.currentData?.customerSignature} // WIll this cause issues?
      enableLogs={import.meta.env.VITE_TE_APP_ENV === "development"}
    >
      <RouterWrapper />
    </AuthWrapper>
  );

  async function onLoginSuccess(token: ITEToken) {
    dispatch(setLoginData(token));

    const userDataPromise = triggerFetchUserData(token)?.unwrap();

    const organizationPromise = triggerFetchOrganization({
      organizationId: token.organizationId,
    }).unwrap();

    const [userData, orgData] = await Promise.all([
      userDataPromise,
      organizationPromise,
    ]);

    triggerFetchRegistration();

    updateTitle({
      firstName: userData?.firstName ?? "",
      organizationName: orgData?.name,
    });
  }

  function onLogout() {
    dispatch(setLoginData(undefined));
  }

  interface UpdateTitle {
    firstName?: string;
    organizationName?: string;
  }
  function updateTitle({ firstName, organizationName }: UpdateTitle) {
    const userDataBreadCrumb = firstName ? `${firstName}` : "";
    const organizationDataBreadCrumb = organizationName
      ? `${organizationName}`
      : "";

    const newTitle = `${organizationDataBreadCrumb} ${userDataBreadCrumb}`;
    document.title = newTitle;
  }
}
