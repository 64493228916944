import {
  AllocationGroupState,
  AllocationStatus,
  RegistrationMode,
  exhaustiveMatchingGuard,
} from "@timeedit/registration-shared";
import { ButtonProps, Modal } from "antd";
import { Button } from "../Button";
import { Text } from "../Typography/Text";
import cn from "classnames";
import "./allocationControls.scss";
import { CheckOutlined, DeleteOutlined, SwapOutlined } from "@ant-design/icons";
import { ReactNode, useMemo, useState } from "react";
import { ConflictNotification } from "../Conflict";

interface Translations {
  noSeatsLeft: string;
  register: string;
  registered: string;
  closed: string;
  swap: string;
  retry: string;
  multipleAllocations: string;
  deallocateConfirm: string;
  deallocate: string;
}

export interface AllocationControlsProps extends ButtonProps {
  trackId: number;
  currentLoadingTrack: number;
  state: AllocationGroupState;
  translations: Translations;
  onSelect: () => void;
  onDelete: () => void;
  full?: boolean;
  clash?: boolean;
  allocationStatus?: AllocationStatus;
  mode?: RegistrationMode;
  open?: boolean;
}

const closedAllowedInMode: Record<RegistrationMode, boolean> = {
  student: true,
  teacher: false,
};
const fullAllowedInMode: Record<RegistrationMode, boolean> = {
  student: true,
  teacher: true,
};

export function AllocationControls({
  state,
  translations,
  onSelect,
  onDelete,
  full = false,
  clash = false,
  allocationStatus = "NOT_ALLOCATED",
  mode = "student",
  open = false,
  trackId,
  currentLoadingTrack,
  ...buttonProps
}: AllocationControlsProps) {
  const { disabled, description } = useMemo(() => {
    // Order important, we show closed message first if it is valid
    const closedDisabled = closedAllowedInMode[mode] && !open;
    if (closedDisabled)
      return { disabled: true, description: translations.closed };

    const fullDisabled =
      full &&
      fullAllowedInMode[mode] &&
      !["ALLOCATED_TO_THIS", "MULTIPLE_ALLOCATIONS"].includes(allocationStatus);

    if (fullDisabled)
      return { disabled: true, description: translations.noSeatsLeft };
    return { disabled: false, description: undefined };
  }, [open, full, mode, translations, allocationStatus]);

  const loadingThis =
    currentLoadingTrack > 0 && currentLoadingTrack === trackId;
  const loadingOther =
    currentLoadingTrack > 0 && currentLoadingTrack !== trackId;

  return (
    <div className={cn("allocation-container", buttonProps.className)}>
      {renderContent()}
    </div>
  );

  function renderContent() {
    switch (state) {
      case "ADJUST":
      case "UNKNOWN":
      case "PREFERENCE":
      case "INCONCLUSIVE":
      case "SELECT":
        return (
          <AllocationButton
            {...buttonProps}
            loading={loadingThis || buttonProps.loading}
            size="large"
            translations={translations}
            clash={clash}
            allocationStatus={allocationStatus}
            disabled={disabled || loadingOther}
            description={description}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        );
      default: {
        return exhaustiveMatchingGuard(state);
      }
    }
  }
}

interface AllocationButtonProps extends ButtonProps {
  translations: Translations;
  onSelect: () => void;
  onDelete: () => void;
  clash: boolean;
  allocationStatus?: AllocationStatus;
  description: ReactNode;
}

function AllocationButton({
  translations,
  onSelect,
  onDelete,
  clash = false,
  description,
  allocationStatus = "NOT_ALLOCATED",
  ...buttonProps
}: AllocationButtonProps) {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  switch (allocationStatus) {
    case "ALLOCATED_TO_THIS": {
      return (
        <>
          <Modal
            open={deleteConfirmOpen}
            title={translations.deallocateConfirm}
            okText={translations.deallocate}
            closeIcon={false}
            onOk={() => {
              onDelete();
              setDeleteConfirmOpen(false);
            }}
            onCancel={() => setDeleteConfirmOpen(false)}
          />
          <ButtonWithDescription
            {...buttonProps}
            description={description}
            className={"buttons--registered"}
            icon={<CheckOutlined />}
            onClick={() => setDeleteConfirmOpen(true)}
          >
            {translations.registered}
          </ButtonWithDescription>
          {clash && <ConflictNotification useText />}
        </>
      );
    }
    case "ALLOCATED_TO_OTHER": {
      return (
        <>
          <ButtonWithDescription
            {...buttonProps}
            description={description}
            icon={<SwapOutlined />}
            onClick={onSelect}
          >
            {translations.swap}
          </ButtonWithDescription>
          {clash && <ConflictNotification useText />}
        </>
      );
    }
    case "NOT_ALLOCATED": {
      return (
        <>
          <ButtonWithDescription
            {...buttonProps}
            description={description}
            onClick={onSelect}
          >
            {translations.register}
          </ButtonWithDescription>
          {clash && <ConflictNotification useText />}
        </>
      );
    }
    case "MULTIPLE_ALLOCATIONS": {
      return (
        <>
          <div className={"buttons"}>
            <Button
              {...buttonProps}
              danger
              onClick={onDelete}
              icon={<DeleteOutlined />}
            />
            <Text type="danger" disabled={buttonProps.disabled}>
              {translations.multipleAllocations}
            </Text>
          </div>
          {clash && <ConflictNotification useText />}
        </>
      );
    }
    default: {
      return exhaustiveMatchingGuard(allocationStatus);
    }
  }
}

interface ButtonWithDescriptionProps extends ButtonProps {
  description?: ReactNode;
  onDelete?: () => void;
}

function ButtonWithDescription({
  description,
  ...buttonProps
}: ButtonWithDescriptionProps) {
  return (
    <>
      <div className={"buttons"}>
        <Button
          {...buttonProps}
          disabled={buttonProps["aria-disabled"] ? false : buttonProps.disabled}
          className={cn(buttonProps.className)}
        >
          {buttonProps.children}
        </Button>
      </div>
      <Text>{description}</Text>
    </>
  );
}
